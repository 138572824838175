/********************************************************************************
Filename: np-years-in-mocap.js
Created: 03 May 2021
Author: Ron Robertson
Description: Displays time since start year
Usage Example:
<np-years-in-mocap year='2019'></np-years-in-mocap>
********************************************************************************/

(function() {
    const template = document.createElement('template');

    template.innerHTML = `<span id="yearsInMocap"></span><!--END yearsInMocap-->`;

    class NpYearsInMocap extends HTMLElement {
        constructor() {
            /* Always call super first in constructor */
            super();

            /* bind 'this' to class in the method */
            this.calcYears = this.calcYears.bind(this);

            /* Use the Shadow DOM */
            this.attachShadow({ mode: 'open' });
            this.shadowRoot.appendChild(template.content.cloneNode(true));

            /* Reference to DOM Elements */
            this.yearsInMocap = this.shadowRoot.getElementById("yearsInMocap");

        } /* END constructor */

        /* Called when added to DOM */
        connectedCallback() {

            /* Set default attribute value */
            if (!this.hasAttribute('year')) {
                this.setAttribute('year', "");
            }

        } /* END connectedCallback */

        /* Listen for when attributes change */
        /* attributes must be lowercase */
        static get observedAttributes() {
            return ['year'];
        }

      /* Called when attributes change */
      attributeChangedCallback(name, oldValue, newValue) {

        if(name === 'year') {
           this.calcYears();
        }
       }/* END attributesChangedCallback */

      /* Getting attributes */
      get year() {
        return this.getAttribute('year');
      }

      /* Settinng attributes */
      set year(newValue) {
        this.setAttribute('year', newValue);
      }

        /* Method */
        calcYears() {
            var now = new Date();
            var currentYear = now.getFullYear();
            var yearsIn = currentYear - parseInt(this.year);

            this.yearsInMocap.innerHTML = yearsIn+" years in motion capture";
            /* Do something here */
        }

    } /* END class */

    window.customElements.define('np-years-in-mocap', NpYearsInMocap);

  })();
